import { blue, cyan, purple, red, yellow } from '@asaprint/asap/theme.js';
import { progressBarStripes } from '@asaprint/common/helpers/animations.js';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx.js';

export enum ProductStatus {
  NEW = 'new',
  OPEN = 'open',
  PROCESSING = 'processing',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  DONE = 'done',
}

export enum EstimatedTimeType {
  NOT_SET,
  EXACT,
  ORIENTATIONAL,
}

export const PRODUCT_STATUS_NAMES = {
  [ProductStatus.NEW]: 'Nový',
  [ProductStatus.OPEN]: 'Otvorený',
  [ProductStatus.PROCESSING]: 'Prebieha',
  [ProductStatus.PAUSED]: 'Pozastavený',
  [ProductStatus.CANCELED]: 'Stornovaný',
  [ProductStatus.DONE]: 'Dokončený',
};

export const ESTIMATED_TIME_TYPE_NAMES = {
  [EstimatedTimeType.NOT_SET]: 'Neurčený',
  [EstimatedTimeType.EXACT]: 'Stanovený presne',
  [EstimatedTimeType.ORIENTATIONAL]: 'Stanovený orientačne',
};

export const statusSx: Record<ProductStatus | 'active', SystemStyleObject> = {
  [ProductStatus.NEW]: {
    backgroundColor: yellow,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [ProductStatus.OPEN]: {
    backgroundColor: red,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [ProductStatus.PROCESSING]: {
    backgroundColor: cyan,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [ProductStatus.PAUSED]: {
    backgroundColor: purple,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [ProductStatus.CANCELED]: {
    backgroundColor: blue,
    color: (theme: Theme) => theme.palette.common.white,
  },
  [ProductStatus.DONE]: {
    backgroundColor: '#000',
    color: (theme: Theme) => theme.palette.common.white,
  },
  active: {
    backgroundImage:
      'linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)',
    backgroundSize: '1rem 1rem',
    animation: `${progressBarStripes} 1s linear infinite`,
  },
};

export const statusOptions = [
  { label: PRODUCT_STATUS_NAMES[ProductStatus.NEW], value: ProductStatus.NEW },
  { value: ProductStatus.OPEN, label: PRODUCT_STATUS_NAMES[ProductStatus.OPEN] },
  {
    value: ProductStatus.PROCESSING,
    label: PRODUCT_STATUS_NAMES[ProductStatus.PROCESSING],
  },
  {
    value: ProductStatus.PAUSED,
    label: PRODUCT_STATUS_NAMES[ProductStatus.PAUSED],
  },
  {
    value: ProductStatus.CANCELED,
    label: PRODUCT_STATUS_NAMES[ProductStatus.CANCELED],
  },
  { value: ProductStatus.DONE, label: PRODUCT_STATUS_NAMES[ProductStatus.DONE] },
];
